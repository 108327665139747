import React, { useEffect, useState } from "react";
import AppRouter from "./shared/components/AppRouter";
import { ThemeProvider } from "styled-components";
import { darkTheme, GlobalStyles, lightTheme, Loader } from "@bjsdistribution/tms-component-library";

import { Provider, useSelector } from "react-redux";
import TmsStore from "./shared/redux/store";
// import GlobalStroe from "./shared/redux/store";

function App() {

  const [theme, setTheme] = useState("light"); 
  // const toggleTheme = () => {
  //   setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  // };
  // const { theme } = useSelector((state: any) => state.appMangerUtilitySlice);

  return (
    <>
      <Provider store={TmsStore}>
        <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        {  <Loader />}
          <AppRouter setTheme={setTheme} />
          <GlobalStyles />
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;
