import { createSlice } from "@reduxjs/toolkit";

export interface UtilityType {
  toggleMenu: boolean;
}

const initialState: UtilityType = {
  toggleMenu: true,
};

export const appMangerUtilitySlice = createSlice({
  name: "appMangerUtilitySlice",
  initialState,
  reducers: {
    setToggleMenu: (state, action) => {
      state.toggleMenu = action.payload;
    },
  
  },
});

export const { setToggleMenu } = appMangerUtilitySlice.actions;

export default appMangerUtilitySlice.reducer;
