import { configureStore } from "@reduxjs/toolkit";

import appManagerUiRenderSlice from "./features/appManagerUiRenderSlice";
import appMangerUtilitySlice from "./features/appMangerUtilitySlice";
import {
  onBoardingRequestedSlice,
  OnboardingTableFilterSlice,
  OnboardingTableFilterDeclineSlice,
  OnboardingStaticDataSlice,
  OnboardingUiRenderSlice,
  OnboardingutilitySlice,
} from "@bjsdistribution/tms-onboarding";
import { addVehicleFiltersDataSlice, addVehicleFormSlice, addVehicleStaticDataSlice, addVehicleUtilitySlice } from "@bjsdistribution/tms-add-vehicle";
import {
  breakDownTableFilterDeclineSlice,
  breakDownTableFiltersSlice,
  breakDownUiRenderSlice,
  breakDownUtilitySlice,
  checksTableFiltersSlice,
  checksUiRenderSlice,
  checksUtilitySlice,
  defectBreakDownCreateSlice,
  defectTableFilterDeclineSlice,
  defectTableFiltersSlice,
  defectUiRenderSlice,
  defectUtilitySlice,
  driverListSlice,
  maintenanceGlobalUiRenderSlice,
  maintenanceGlobalUtilitySlice,
  maintenanceStaticDataSlice,
  maintenanceTableFiltersSlice,
  maintenanceUiRenderSlice,
  maintenanceUtilitySlice,
  workshopListSlice,
} from "@bjsdistribution/tms-vehicle-maintenance";

import { hrStaticDataReducer, hrUiRenderReducer, hrPersonalDetailReducer, hrDashboardReducer, hrHeadcountReducer } from "@bjsdistribution/tms-hr";

const TmsStore = configureStore({
  reducer: {
    // App manager slices
    appManagerUiRenderSlice,
    appMangerUtilitySlice,
    // Tms Onboarding
    onBoardingRequestedSlice,
    OnboardingTableFilterSlice,
    OnboardingTableFilterDeclineSlice,
    OnboardingStaticDataSlice,
    OnboardingUiRenderSlice,
    OnboardingutilitySlice,
    // Tms Add vehicle
    addVehicleStaticDataSlice,
    addVehicleUtilitySlice,
    addVehicleFormSlice,
    addVehicleFiltersDataSlice,
    // maintenance Global
    maintenanceGlobalUtilitySlice,
    defectBreakDownCreateSlice,
    maintenanceGlobalUiRenderSlice,
    maintenanceStaticDataSlice,
    // Defects slice
    defectTableFiltersSlice,
    defectTableFilterDeclineSlice,
    defectUiRenderSlice,
    defectUtilitySlice,
    // Breakdown slice
    breakDownTableFiltersSlice,
    breakDownTableFilterDeclineSlice,
    breakDownUiRenderSlice,
    breakDownUtilitySlice,
    workshopListSlice,
    driverListSlice,
    // Checks
    checksTableFiltersSlice,
    checksUiRenderSlice,
    checksUtilitySlice,

    // maintenance
    maintenanceTableFiltersSlice,
    maintenanceUiRenderSlice,
    maintenanceUtilitySlice,

    //hr
    hrStaticDataReducer,
    hrUiRenderReducer,
    hrPersonalDetailReducer,
    hrDashboardReducer,
    hrHeadcountReducer,
  },
});

export default TmsStore;
