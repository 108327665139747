import React from "react";

const ArrowDownIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
      <g opacity="0.8">
        <rect width="23.4765" height="22" transform="translate(0.523438 0.5)" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.06877 9.56704C7.20082 9.44345 7.37983 9.37402 7.56647 9.37402C7.75311 9.37402 7.93211 9.44345 8.06417 9.56704L12.2618 13.5006L16.4594 9.56704C16.5929 9.45046 16.7695 9.38699 16.9519 9.39001C17.1344 9.39303 17.3085 9.46229 17.4375 9.58322C17.5666 9.70414 17.6405 9.86728 17.6437 10.0383C17.6469 10.2092 17.5792 10.3747 17.4548 10.4998L12.7595 14.8998C12.6274 15.0234 12.4484 15.0929 12.2618 15.0929C12.0751 15.0929 11.8961 15.0234 11.7641 14.8998L7.06877 10.4998C6.93688 10.3761 6.86279 10.2083 6.86279 10.0334C6.86279 9.85854 6.93688 9.69079 7.06877 9.56704Z"
          fill={`#717196`}
        />
      </g>
    </svg>
  );
};

export default ArrowDownIcon;
